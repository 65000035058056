/*Whole Page Container*/
.TruckMenu_ItemPage{
    margin: 30px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}
/*ReturnToMenu Button*/
.TruckMenu_ItemPage_Back{
    margin: 10px auto 0px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 120px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}
/*-----------------------------------------------------------------------*/
/*Container for Name + Price + Options*/
.TruckMenu_ItemPage_Item_Info{
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 300px;
}

/*Dish Image*/
.TruckMenu_ItemPage_Item_Image img{
    margin: 10px auto auto auto;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 150px; 
    width: 200px;
    object-fit: cover;
    
    border: 1px solid #000000;
    border-radius: 5px;
}

/*Dish Name*/
.TruckMenu_ItemPage_Item_Info h3{
    margin: 10px auto auto auto;
    font-size: 24px;
    font-weight: 600; 
    color: black
}

/*Dish Price*/
.TruckMenu_ItemPage_Item_Info h4{
    margin: 10px auto auto auto;
    font-size: 20px;
    font-weight: 400; 
    color: black
}

/*Options Container e.g Add On / Sauce etc*/
.TruckMenu_ItemPage_Item_Options_Group {
    margin: 20px auto auto auto;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border: 1px solid rgb(170, 169, 169);
    border-radius: 5px;
}

    /*Option Name*/
    .TruckMenu_ItemPage_Item_Options_Group h4{
        margin: 10px 0px 10px 0px;
        font-size: 20px;
        font-weight: 600; 
        color: black
    }

    /*Container individual option name + option price*/
    .TruckMenu_ItemPage_Item_Group_Option{
        margin: 10px auto 10px auto;
    }

        /*Individual Option name e.g. Ham Cheese Tomato*/
        .TruckMenu_ItemPage_Item_Group_Option h5{
            /*Display*/
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            margin: 0px 0px 0px 0px;
            font-size: 16px;
            font-weight: 400; 
            color: black
        }

        /*Individual Option price + box*/
        .TruckMenu_ItemPage_Item_Group_Option h6{
            margin: 0px 0px 0px 0px;
            font-size: 16px;
            font-weight: 400; 
            color: black
        }

                /*Individual Option label*/
                .TruckMenu_ItemPage_Item_Group_Option h4{
                    margin: 0px 0px 0px 20px;
                    font-size: 16px;
                    font-weight: 400; 
                    color: black
                }
/*-----------------------------------------------------------------------*/
/*Container for Total + Quantity + Cart + Add*/
.TruckMenu_ItemPage_Total{
    margin: 10px auto auto auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 300px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: black;
    /*Border*/
    border: 1px solid #2c74b4;
    border-radius: 5px;
}

/*Total Price*/
.TruckMenu_ItemPage_Total_Price h3{
    margin: 10px 0px 0px 0px;
    font-size: 20px;
    font-weight: 600; 
    color: black
}

/*Container Quantity*/
.TruckMenu_ItemPage_Quantity{
    margin: 10px auto 10px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 5px;
}
    /*Quantity Value*/
    .TruckMenu_ItemPage_Quantity p{
        margin: 0px 20px 0px 5px;
        font-size: 20px;
        font-weight: 600; 
        color: black
    }

    /*Quantity Text*/
    .TruckMenu_ItemPage_Quantity h1{
        margin: 0px auto 0px auto;
        font-size: 18px;
        font-weight: 600; 
        color: black
    }

/*Button add to cart*/
.TruckMenu_ItemPage_Total_Price_Buttons_AddToCart h1{
    margin: 0px auto 0px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 120px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

/*Button go to cart*/
.TruckMenu_ItemPage_Total_Price_Buttons_toCart{
    margin: 10px auto 10px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 120px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

