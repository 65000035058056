/*Empty*/
.OrderEmpty{
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}

    .OrderEmpty h1{
        margin: 20px auto 0px auto;
        font-size: 24px;
        font-weight: 600; 
        color: black
    }

    .OrderEmpty h3{
        margin: 20px auto 0px auto;
        font-size: 20px;
        font-weight: 400; 
        color: black;
        height: 100%;
        width: 200px;
    }

    .OrderEmpty h2{
        margin: 20px 0px 0px 0px;
        /*Display*/
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*H+W*/
        height: 40px;
        width: 80px;
        /*Font*/
        font-size: 16px;
        font-weight: 400;
        color: white; 
        /*Border*/
        border: 1px solid #000000;
        border-radius: 5px;
        /*Color*/
        background-color: #2c74b4;
        }
    



/*Whole Page container*/
.OrderSummary{
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}

/*Header Text (order summary)*/
.OrderSummary_Header{
    margin: 10px auto 20px auto;
    font-size: 24px;
    font-weight: 600; 
    color: black
}
/*----------------------------------------------------------------------*/
/*Each Item Container*/
.OrderSummary_item_whole{
    padding: 5px 5px 5px 5px;
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /*H+W*/
    width: 300px;
    height: 100%;
    border: 1px solid rgb(170, 169, 169);
    border-radius: 5px;
}

.OrderSummary_item_excOptions{
    padding: 5px 5px 5px 5px;
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /*H+W*/
    width: 100%;
    height: 100%;
}

/*Each Item Container*/
.OrderSummary_item_Name_Price{
    margin: 0px 40px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}


/*Each item name*/
.OrderSummary_item_name {
    margin: 10px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600; 
    color: black;
}

/*Each item quantity +-*/
.OrderSummary_item_Quantity_Remove{
    margin: 0px 20px 0px 0px;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 120px;
}

/*Each item quantity +-*/
.OrderSummary_item_quantity{
    margin: 0px 30px 0px 10px;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /*H+W*/
    height: 40px;
    width: 120px;
}

    .OrderSummary_item_quantity h1{
        margin: 0px auto 0px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        font-size: 20px;
        font-weight: 600; 
        color: black;
    }

    .OrderSummary_item_quantity h2{
        margin: 0px auto 0px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        font-size: 20px;
        font-weight: 600; 
        color: black;
    }

/*Remove Item Button*/
.OrderSummary_item_remove button{
    /*Display*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 80px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

/*Each Item Price*/
.OrderSummary_item_price {
    margin: 0px auto auto auto;
    font-size: 18px;
    font-weight: 400; 
    color: black;
    padding: 5px 5px 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.OrderSummary_item_options{
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 12px;
    font-weight: 400; 
    color: black;
}
    .OrderSummary_item_option{
        margin: 0px 0px auto 10px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400; 
        color: black;
    }

/*----------------------------------------------------------------------*/
/*Tips*/
.OrderSummary_tip{
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}

/*Tips Qs Title*/
.OrderSummary_tip h2{
    margin: 10px auto auto auto;
    font-size: 24px;
    font-weight: 600; 
    color: black;
}

/*Tips Qs buttons container*/
.OrderSummary_tip__buttons{
    margin: 0px auto 10px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    height: 100%;
}

/*Tips Qs each button*/
.OrderSummary_tip__buttons Button{
    margin: 10px 5px 0px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 120px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

/*----------------------------------------------------------------------*/
/*Total container*/
.OrderSummary_total{
    margin: 10px 5px 0px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*Tips Qs Title*/
.OrderSummary_total h2{
    margin: 20px auto auto auto;
    font-size: 24px;
    font-weight: 600; 
    color: black
}

/*Total commission*/
.OrderSummary_total h3{
    margin: 10px auto auto auto;
    font-size: 16px;
    font-weight: 400; 
    color: black
}

/*Total Tip*/
.OrderSummary_total h4{
    margin: 20px auto auto auto;
    font-size: 16px;
    font-weight: 400; 
    color: black
}

/*Tips Qs Title*/
.OrderSummary_total h5{
    margin: 10px 5px 0px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 120px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}


/*----------------------------------------------------------------------*/
