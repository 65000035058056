.TruckMenu{
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}

.TruckMenu_Header{
    margin: 20px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 24px;
    font-weight: 600; 
    color: black
}

.TruckMenu_Menu_Header_Button button{
    margin: 20px 0px 10px 0px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 120px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

.Completion_Container_Info_Truck_Photo img{
    margin: 10px 10px 10px 10px;
    align-items: center;
    max-width: 300px;
    border: 1px solid #000000;
    border-radius: 5px;
}

.TruckMenu_Header_Rating {
    margin: 10px 0px 5px 0px;
    display: flex;
    flex-direction: row; 
}

.TruckMenu_Header_Rating h1 {
    margin: 5px 0px 0px 5px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 600;   
}

.TruckMenu_Header_Location {
    margin: 10px 0px 0px 5px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;   
}
.TruckMenu_Header_Location h1 {
    margin: 5px 0px 0px 5px;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 600;   
}

.TruckMenu_Menu_Button button{
    margin: 0px 0px 0px 0px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 40px;
    width: 120px;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

.TruckMenu_About p{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 300px;
    font-size: 16px;
    font-weight: 400; 
    color: black
}

.TruckMenu_About h3{
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 600; 
    color: black
}