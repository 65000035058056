.Dashboard{
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
}

.NewOrders{
    min-height: 100px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #0059b2
}

.LatestTransactions{
    min-height: 100px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #0059b2
}
