.TransactionCompletion{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
}

.TransactionComplete_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.TransactionComplete_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.TransactionComplete_info h3{
    font-size: 2.0rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 1rem;
}