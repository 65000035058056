.RegisterPage {
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 350px;
}
 
.header {
    margin-top: 20px;
    margin-left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color : black;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 600;
}


.RegisterPage_Container {
    margin: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.RegisterPage_FormContainer {
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}

.RegisterPage_FormContainer Form {
    margin: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: rgb(0, 0, 0);
}

.RegisterPage_Form label {
    margin: 10px 10px 0px 10px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(66, 66, 66);
}

.RegisterPage_Form input{
    width: 80%;
    height: 50%;
    margin: 10px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
}

.RegisterPage_Form span{
    font-size: 18px;
    font-weight: 400;
    color: red;
    -webkit-text-stroke-width: 0.05px;
    -webkit-text-stroke-color: black;
}

.RegisterPage_Form button{
    margin: 20px 10px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 150px;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #2c74b4;
}

.RegisterPage_Form button:hover{
    background-color: #09e22a;
    color: white;
    transition: ease-in 0.2s;
}

.RegisterPage_Form button:active{
    background-color: #09e22a;
    color: white;
    transition: ease-in 0.2s;
}

.RegisterPage_Modal{
    width: 100%;
    height: 100%;
}

#RegisterPage_Modal h2{
    text-align: center;
    font-size: 18px;
    font-weight: 600; 
}

#RegisterPage_Modal p{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-align: center;
}

#RegisterPage_Modal Button{
    width: 100%;
    max-width: 200px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
}

