.Trucks_container{
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 350px;
}

.Truck{
    margin: 20px auto auto auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 140px; 
    width: 350px;
    
    border: 2px solid #2c74b4;
    border-radius: 5px;

    background-color: #0000;

    box-shadow: 0 0 10px 0 rgba(48,84,148,2);
}


/*Truck Info*/
.Truck_info{
    margin: 10px 0px 10px 5px;

    height: 120px; 
    width: 180px;
    
    border: 1px solid #000000;
    border-radius: 5px;

    font-size: 16px;
    font-weight: 400;
    color: white; 
    background-color: #2c74b4;
}

    .Truck_info h3{
        margin: 10px 5px 0px 5px;
        font-size: 18px;
        font-weight: 600;
        color: white; 
    }

    .Truck_rating{
        margin: 10px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
        
        .Truck_rating h2{
            margin: 0px 5px 0px 5px;
            font-size: 16px;
            font-weight: 400;
            color: white; 
        }

    .Truck_location{
        margin: 10px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
        
        .Truck_location h2{
            margin: 0px 5px 0px 5px;
            font-size: 14px;
            font-weight: 400;
            color: white; 
        }

/*Truck Img*/
.Truck_info_Image_Open_Close{
    margin: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 110px;
    width: 140px;
}
.Truck_info_image img{
    margin: 0px 0px 5px 0px;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 95px; 
    width: 150px;
    object-fit: cover;
    
    border: 1px solid #000000;
    border-radius: 5px;
}

.Truck_info_isOpen {

    margin: 0px 0px 0px 0px;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 20px; 
    width: 140px;

    background-color: greenyellow;
    color: black;
    border-radius: 5px;
    border: 1px solid #000000;
    font-size: 18px;
}

.Truck_info_isClosed {

    margin: 0px 0px 0px 0px;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 20px; 
    width: 140px;

    background-color: crimson;
    color: azure;
    border-radius: 5px;
    border: 1px solid #000000;
    font-size: 18px;
}