/*Your Cart Button*/
.TruckMenu_OrderSummary_Button{
    margin: 10px auto 0px auto;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
    /*Font*/
    font-size: 20px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

/*List of Food options*/

/*List Container*/
.TruckMenu_Menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

/*Category Name e.g. Pizza Pasta Sandwich*/
.TruckMenu_Tabs h3{
    margin: 20px auto 10px auto;
    font-size: 22px;
    font-weight: 600;
}

/*Each Option Container*/
.TruckMenu_Tabs_Item_Option{
    margin: auto auto auto auto;
    padding: 10px;
    width: 300px;
    height: 100px;
    /*Display*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /*Border + Shadow*/
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
}

/*Each Option Container*/
.TruckMenu_Tabs_Item_Option h1{
    margin: auto auto auto auto;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

/*Dish Name*/
.TruckMenu_Tabs_Item_Option h2{
    margin: auto auto 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*Font*/
    font-size: 18px;
    font-weight: 600; 
    color: black
}

/*Dish Price*/
.TruckMenu_Tabs_Item_Option h3{
    margin: auto auto auto auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*Font*/
    font-size: 16px;
    font-weight: 400; 
    color: black
}

/*Each Option Image*/
.TruckMenu_Tabs_Item_Image img{
    margin: auto auto auto auto;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100px; 
    width: 120px;
    object-fit: cover;
    

    border-radius: 5px;
}
