.LoginPage{
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}


.LoginPage_Form h1 {
    padding-bottom: 0px;
    font-size: 24px;
    font-weight: 600;  
    color: Black;  
}



.LoginPage_Form { 
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}

.LoginPage_Form Form{ 
    margin: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #ffffff;
}

.LoginPage_Form Button{ 
    margin: 20px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 150px;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #2c74b4;
}

.LoginPage_Form input{
    margin: 10px 0px 0px 0px;
    width: 90%;
    max-width: 300px;
    height: 20px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
}

.LoginPage_Form input:focus{
    height: 30px;
    border: 2px solid rgb(6, 209, 50);
    transition: ease-in 0.2s;
}

.LoginPage_Form label{
    font-size: 18px;
    font-weight: 600;
    color: rgb(69, 69, 69); 
}

.Buttons{
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.BottomButtons{
    margin: 0px 10px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    border-radius: 10px;
    background-color: #2c74b4;
}

.LoginPage_Form button:hover{
    background-color: rgb(53, 212, 0);
    color: white;
    cursor: pointer;
    transition: ease-in 0.2s;
}

