.CustomCheckout{
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
}

.CustomCheckout h1{
    margin: 10px auto 10px auto;
    font-size: 24px;
    font-weight: 600; 
    color: black
}

.AmountInput{
    margin: 10px auto 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 350px;
    height: 100%;

    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: #333;
}

.Email{
    margin: 10px auto 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 350px;
    height: 100%;

    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    color: #333;
}

.TipSelect{
    width: 100%;
    margin: 10px auto 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 350px;
    height: 100%;

    font-size: 24px;
    font-weight: 600; 
    color: black
}

.TipSelect_Buttons{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.TipSelect_button{
    margin: 10px 5px 0px 5px;
    padding: 5px 5px 5px 5px;
    /*Display*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*H+W*/
    height: 100%;
    width: 100%;
    /*Font*/
    font-size: 16px;
    font-weight: 400;
    color: white; 
    /*Border*/
    border: 1px solid #000000;
    border-radius: 5px;
    /*Color*/
    background-color: #2c74b4;
}

.CustomCheckout_Total{
    margin: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*H+W*/
    height: 100%;
    width: 100%;
}

.CustomCheckout_Total_top{
    margin: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*H+W*/
    height: 100%;
    width: 100%;
}

.CustomCheckout_Total_top h4{
    margin: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*H+W*/
    height: 100%;
    width: 100%;

    font-size: 18px;
    font-weight: 400; 
    color: black;
}

.CustomCheckout_Total_bottom{
    margin: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*H+W*/
    height: 100%;
    width: 100%;
}

.CustomCheckout_Total_bottom h3{
    margin: 10px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*H+W*/
    height: 100%;
    width: 100%;

    font-size: 22px;
    font-weight: 600; 
    color: black;
}