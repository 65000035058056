a{
    text-decoration: none;
}
h3{
    color: black;
    font-size: 1.5em;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.BurgerMenu_Logo{
    display: flex;
    flex-direction: column;
    margin-right: auto;
    float: right;
    height: 100px;
    width: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.BurgerMenu_Logo a{
    color: black;
    font-size: 1.5em;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.BurgerMenu_Logo a:hover{
    color: #f5f5f5;
}

.BurgerMenu_Logo img{
    width: 80%
}