.Navbar{
    height: 100px;
    position: relative;
    width: 100%;
    z-index: 100;
}

.Navbar_Left{
    float: left;
    height: 50px;
    width: 50px;
}

.Navbar_Right{
    float: right;
    width: 150px;
    height: 50px;
}

.Navbar_Right img{
    width: 100%;
}

.Navbar_Right h3{
    color: #000;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: center;
}