.FAQ{
    background-color: rgb(255, 255, 255);
    padding: 50px 0;
    margin-top: 50px;
}
.FAQ_Header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

.FAQ h1{
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.FAQ p{
    font-size: 1.2rem;
    font-weight: 400;
    margin: 10px;
    padding: 0;
}

.FAQContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    padding: 0;
}