.Completion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px #ccc;
}

.Completion h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.Completion img{
    width: 100%;
    border-radius: 5px;
}
